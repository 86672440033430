var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("地址验证")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container info-card" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "search-form",
              attrs: {
                inline: true,
                rules: _vm.validateForm,
                model: _vm.editForm,
                "label-width": "90px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return (() => {}).apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "", prop: "status" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "GLP订单单号", clearable: "" },
                    model: {
                      value: _vm.editForm.package_num,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editForm,
                          "package_num",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editForm.package_num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "", prop: "status" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "用户编码", clearable: "" },
                    model: {
                      value: _vm.editForm.user_code,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editForm,
                          "user_code",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editForm.user_code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "", prop: "status" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择状态" },
                      on: { change: _vm.search },
                      model: {
                        value: _vm.editForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "status", $$v)
                        },
                        expression: "editForm.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "待核对", value: 4 } }),
                      _c("el-option", { attrs: { label: "已核对", value: 1 } }),
                      _c("el-option", { attrs: { label: "已创建", value: 2 } }),
                      _c("el-option", { attrs: { label: "异常", value: 3 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "form-item-input", attrs: { label: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择客户类型" },
                      on: { change: _vm.search },
                      model: {
                        value: _vm.editForm.role,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "role", $$v)
                        },
                        expression: "editForm.role",
                      },
                    },
                    _vm._l(_vm.userRoles, function (item) {
                      return _c("el-option", {
                        key: item.role,
                        attrs: { label: item.name, value: item.role },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.search },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("el-row", { attrs: { gutter: 30, span: 24 } }, [
            _c(
              "div",
              { staticClass: "ctrl" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      plain: "",
                      size: "small",
                      border: "",
                      disabled: !_vm.selectedRows.length,
                    },
                    on: { click: _vm.batchMarkAsValidate },
                  },
                  [_vm._v("批量标记")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "container info-card g-table" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.isLoading,
                        expression: "isLoading",
                      },
                    ],
                    ref: "addressTable",
                    staticClass: "ordering-list",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.list,
                      stripe: "",
                      "row-class-name": _vm.getRowClassName,
                      "element-loading-text": "加载中",
                      "element-loading-spinner": "el-icon-loading",
                    },
                    on: {
                      "selection-change": _vm.handleSelectionChange,
                      "row-click": _vm.handleRowClick,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "selection", width: "55" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "package.package_num",
                        label: "包裹单号",
                        fixed: "left",
                        width: "150",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "user.code",
                        label: "客户编码",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "客户类型", width: "90" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: _vm.formatUserRole(
                                      scope.row.user.role
                                    ).tag_type,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatUserRole(scope.row.user.role)
                                        .name
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status_pay",
                        label: "核对状态",
                        width: "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status === 4
                                ? _c("el-tag", { attrs: { size: "mini" } }, [
                                    _vm._v("待核对"),
                                  ])
                                : _vm._e(),
                              scope.row.status === 2
                                ? _c(
                                    "el-tag",
                                    { attrs: { type: "info", size: "mini" } },
                                    [_vm._v("已创建")]
                                  )
                                : _vm._e(),
                              scope.row.status === 1
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "mini" },
                                    },
                                    [_vm._v("已核对")]
                                  )
                                : _vm._e(),
                              scope.row.status === 3
                                ? _c(
                                    "el-tag",
                                    { attrs: { type: "danger", size: "mini" } },
                                    [_vm._v("异常")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status_pay",
                        label: "支付状态",
                        width: "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.package.status_pay === 2
                                ? _c("el-tag", { attrs: { size: "mini" } }, [
                                    _vm._v("待支付"),
                                  ])
                                : scope.row.package.status_pay === 1
                                ? _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "mini" },
                                    },
                                    [_vm._v("已支付")]
                                  )
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "package.country",
                        label: "国家/地区",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "州", width: "120" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: { placeholder: "州" },
                                    model: {
                                      value: scope.row.package.state,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row.package,
                                          "state",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "scope.row.package.state",
                                    },
                                  })
                                : _c("p", [
                                    _vm._v(_vm._s(scope.row.package.state)),
                                  ]),
                              scope.row.status == 4 &&
                              scope.row.package.state.toLowerCase() !=
                                scope.row.result.data.state.toLowerCase()
                                ? _c("p", [
                                    _c(
                                      "span",
                                      { staticClass: "validate-fail" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning-outline",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.result.data.state ||
                                                "无匹配"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : scope.row.is_edited
                                ? _c("p", [_vm._v(" ")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "城市", width: "180" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: { placeholder: "城市" },
                                    model: {
                                      value: scope.row.package.city,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row.package,
                                          "city",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "scope.row.package.city",
                                    },
                                  })
                                : _c("p", [
                                    _vm._v(_vm._s(scope.row.package.city)),
                                  ]),
                              scope.row.status == 4 &&
                              scope.row.package.city.trim().toLowerCase() !=
                                scope.row.result.data.city.toLowerCase()
                                ? _c("p", [
                                    _c(
                                      "span",
                                      { staticClass: "validate-fail" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning-outline",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.result.data.city ||
                                                "无匹配"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : scope.row.is_edited
                                ? _c("p", [_vm._v(" ")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "邮编", "min-width": "130" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: { placeholder: "邮编" },
                                    model: {
                                      value: scope.row.package.zipcode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row.package,
                                          "zipcode",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.package.zipcode",
                                    },
                                  })
                                : _c("p", [
                                    _vm._v(_vm._s(scope.row.package.zipcode)),
                                  ]),
                              scope.row.status == 4 &&
                              scope.row.package.zipcode !=
                                scope.row.result.data.postal_code &&
                              scope.row.package.zipcode !=
                                scope.row.result.data.full_postal_code
                                ? _c("p", [
                                    _c(
                                      "span",
                                      { staticClass: "validate-fail" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning-outline",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.result.data
                                                .postal_code || "无匹配"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : scope.row.is_edited
                                ? _c("p", [_vm._v(" ")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "地址1", "min-width": "230" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: {
                                      placeholder: "地址1",
                                      type: "textarea",
                                      "show-word-limit": "",
                                      maxlength: "100",
                                      autosize: "",
                                    },
                                    model: {
                                      value: scope.row.package.address1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row.package,
                                          "address1",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.package.address1",
                                    },
                                  })
                                : _c("p", [
                                    _vm._v(_vm._s(scope.row.package.address1)),
                                  ]),
                              scope.row.status == 4 &&
                              scope.row.package.address1.trim().toLowerCase() !=
                                scope.row.result.data.address_line1.toLowerCase()
                                ? _c("p", [
                                    _c(
                                      "span",
                                      { staticClass: "validate-fail" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning-outline",
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            scope.row.result.data
                                              .address_line1 || "无匹配"
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : scope.row.is_edited
                                ? _c("p", [_vm._v(" ")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "地址2", "min-width": "180" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c("el-input", {
                                    attrs: {
                                      placeholder: "地址1",
                                      type: "textarea",
                                      "show-word-limit": "",
                                      maxlength: "100",
                                      autosize: "",
                                    },
                                    model: {
                                      value: scope.row.package.address2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row.package,
                                          "address2",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "scope.row.package.address2",
                                    },
                                  })
                                : _c("p", [
                                    _vm._v(_vm._s(scope.row.package.address2)),
                                  ]),
                              _c("p", [_vm._v(" ")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status_pay",
                        label: "结果",
                        "min-width": "250",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.status != 2 &&
                              scope.row.result &&
                              scope.row.result.code == 400
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.status == 1
                                            ? "已核对"
                                            : scope.row.result.message
                                        ) +
                                        " "
                                    ),
                                  ])
                                : scope.row.status == 2
                                ? _c("p", [_vm._v(" 待程序自动验证 ")])
                                : _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.result &&
                                            scope.row.result.data
                                            ? scope.row.result.data.error_messages.join(
                                                ";"
                                              )
                                            : "无匹配"
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "status_pay",
                        label: "格式化地址",
                        "min-width": "250",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.result && scope.row.result.code == 200
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.result &&
                                            scope.row.result.data
                                            ? scope.row.result.data
                                                .format_address
                                            : "无匹配"
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("p", [_vm._v(" - ")]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "创建时间", width: "150" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatDate")(scope.row.created)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", fixed: "right", width: "230" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.is_edited
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button-group",
                                        [
                                          _c(
                                            "z-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "mini",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updatePackage(
                                                    scope
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-check",
                                              }),
                                              _vm._v("保存"),
                                            ]
                                          ),
                                          _c(
                                            "z-button",
                                            {
                                              attrs: {
                                                type: "info",
                                                size: "mini",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelEdit(scope)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-close",
                                              }),
                                              _vm._v("取消"),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "z-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editPackage(scope)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-edit",
                                          }),
                                          _vm._v("编辑"),
                                        ]
                                      ),
                                      scope.row.status != 1
                                        ? _c(
                                            "z-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.markAsValidateBtn(
                                                    scope
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-edit",
                                              }),
                                              _vm._v("已核对"),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("z-pagination", {
                  attrs: {
                    total: _vm.pageConfig.total,
                    "page-sizes": [10, 20, 50, 100],
                    "page-size": _vm.pageConfig.size,
                    "current-page": _vm.pageConfig.current_page,
                    layout: "sizes, prev, pager, next, jumper, total",
                  },
                  on: {
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.pageConfig, "current_page", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.pageConfig, "current_page", $event)
                    },
                    "size-change": _vm.handelSizeChange,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }