
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { ElTable } from 'element-ui/types/table'
import { formatDate } from '@/utils/date'
import { getUserRole } from '@/utils/utils'

@Component({
	components: { ZButton },
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i', value) : '-'
		}
	},
	beforeRouteLeave(to: any, from: any, next: any) {
		this.$confirm('确认离开当前页面？', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		})
			.then(() => {
				next()
			})
			.catch(() => {
				next(false)
			})
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
		addressTable: ElTable
	}
	private showDetailDrawer = false
	private packageAttrDetail = {
		code: '',
		customs_info: []
	}
	private inputCodeStr = ''
	private userRoles: any = {}
	private orderingResult: any = {}
	private orderingValidateResult: any = {}
	private isLoading = false
	private codesList: any = []
	private list: any = []
	private codeCustomName: any = {}
	private editForm = {
		status: '',
		role: '',
		user_code: '',
		package_num: ''
	}
	private selectedRows = []
	private channelsExpress: any = []
	private validateForm = {
		channel: [{ required: true, message: '请选择排货渠道', trigger: 'change' }]
	}
	private userAddressForm = {
		address1: '',
		address2: '',
		city: '',
		state: '',
		zipcode: ''
	}

	private pageConfig = {
		total: 0,
		size: 20,
		current_page: 1
	}
	@Watch('pageConfig.current_page')
	onPagination() {
		this.search()
	}

	created() {
		this.getUserRole()
		this.search()
	}
	async getUserRole() {
		this.userRoles = await getUserRole()
	}
	handleSelectionChange(val: any) {
		this.selectedRows = val
	}
	handleRowClick(val: any) {
		this.$refs['addressTable'].toggleRowSelection(val)
	}
	handelSizeChange(val: number) {
		this.pageConfig.size = val
		this.search()
	}
	async search() {
		const { status = undefined, role = '', user_code = '' } = this.editForm
		let { package_num = '' } = this.editForm
		if (package_num) {
			const match = package_num.match(/^GLP[0-9]+/)
			if (match) package_num = match[0]
		}
		this.isLoading = true
		const { size, current_page } = this.pageConfig
		const { data } = await this.$axios
			.get('v1/jobline/tools/pending-validate-address', {
				params: {
					role,
					status,
					user_code: user_code.trim(),
					package_num: package_num.trim(),
					page_size: size,
					page_index: current_page
				}
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() =>
				setTimeout(() => {
					this.isLoading = false
				}, 500)
			)
		if (data) {
			this.list = data.list.map((item: any) => {
				if (item.result && item.result.code == 200) {
					const { data: ret } = item.result
					ret['full_postal_code'] = ret.postal_code
					if (ret.postal_code_suffix) {
						ret['full_postal_code'] += `-${ret.postal_code_suffix}`
					}
					if (ret.country == 'US') {
						const match = ret.postal_code.match(/^[0-9]{5}/)
						if (match) ret.postal_code = match[0]
					}
				}
				item.is_edited = false
				return item
			})
			this.pageConfig.total = data.page.total
		}
	}
	async batchMarkAsValidate() {
		const items = this.selectedRows.filter((item: any) => item.status != 1)
		if (items.length == 0) {
			return this.$message.error('请选择未核对的数据提交')
		}
		const isConfirm = await this.$confirm('确认批量标记？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		await Promise.all(
			items.map(async (item: any) => {
				await this.markAsValidate({ row: item, $index: 0 })
			})
		)
	}
	async markAsValidateBtn(scope: any) {
		const isConfirm = await this.$confirm('确认已经核对？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		const { row, $index } = scope
		await this.markAsValidate({ row, $index })
	}
	async markAsValidate({ row, $index }: any) {
		console.log(row)
		const { data } = await this.$axios
			.put(`v1/jobline/tools/mark-address/${row.id}`)
			.catch(() => {
				return {
					data: null
				}
			})
		if (!data) {
			this.$message.error('更新失败')
			return
		}
		row.status = 1
	}
	async removePackage({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		this.codesList.splice($index, 1)
	}
	editPackage({ row, $index }: any) {
		row.is_edited = true
	}
	getRowClassName({ row, $index }: any) {
		return ''
	}

	async cancel(row: any) {
		this.$refs['addressTable'].toggleRowExpansion(row, false)
	}
	async updatePackage({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认保存？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		const { package: packageData } = row
		await this.$axios.put(
			`/v1/jobline/ordering/package/${packageData.package_num}`,
			{
				...packageData
			}
		)
		await this.markAsValidate({ row, $index })
		this.cancelEdit({ row, $index })
		this.$message.success('修改成功')
	}
	cancelEdit({ row, $index }: any) {
		row.is_edited = false
	}

	formatUserRole(role: number) {
		return this.userRoles[role]
	}
}
